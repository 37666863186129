import { animated, config, useSpring } from '@react-spring/web'
import * as uuid from 'uuid'

import styles from './Toggle.css'

export function Toggle({ id = uuid.v4(), options, value, onValueChange, layoutClassName = undefined }) {
  const { activeIndex, animation } = useToggleState()

  if (options?.length !== 2) throw new Error('Toggle should have two options.')

  return (
    <animated.div
      className={cx(styles.component, activeIndex === 0 && styles.isLeftActive, layoutClassName)}
      style={animation}
      {...{ id }}
    >
      { options?.map((x, i) => (
        <Label
          index={i}
          key={x.value}
          label={x.label}
          value={x.value}
          isChecked={value === x.value}
          {...{ id, onValueChange, activeIndex }}
        />
      )) }
    </animated.div>
  )

  function getSpringConfig(x) {
    return {
      '--active-index-spring': x ? 1 : 0,
      config: config.default
    }
  }

  function useToggleState() {
    const activeIndex = options?.map(x => x.value).indexOf(value)
    const [animation] = useSpring(() => getSpringConfig(activeIndex), [value])

    return { activeIndex, animation }
  }
}

function Label({ activeIndex, id, index, value, label, isChecked, onValueChange }) {
  return (
    <label
      data-index={index}
      title={label}
      className={cx(
        styles.componentLabel,
        activeIndex === 0 && styles.backgroundLeft,
        activeIndex === 1 && styles.backgroundRight,
      )}
    >
      <span>{ label }</span>
      <input
        type='radio'
        name={`toggle-${id}`}
        defaultChecked={isChecked}
        onClick={() => onValueChange(value)}
        {...{ value }}
      />
    </label>
  )
}
