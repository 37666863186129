import { animated, useSpringRef, useTransition } from '@react-spring/web'

import { routeMap } from '/routeMap'
import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useParams } from '/machinery/Params'

import { IssueSliderSpecials, IssueSliderWithArchive } from '/features/buildingBlocks/IssueSlider'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { Toggle } from '/features/buildingBlocks/Toggle'

import mediaStyles from '/cssGlobal/media.css'
import styles from './CardUitgelezenContent.css'

import mapIcon from '/images/icons/map.raw.svg'

export function CardUitgelezenContent({ title, layoutClassName, issues, specials, animations = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.headingAndButton}>
        <Heading animation={animations && animations.headingGroup} {...{ title }} />
        <Button animation={animations && animations.ctaButton} />
      </div>

      <ContainerLg layoutClassName={styles.containerLayout}>
        <Slider {...{ issues, specials, animations }} />
      </ContainerLg>
    </div>
  )
}

function Slider({ issues }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSlider)}>
      <div className={styles.sliderContainer}>
        <IssueSliderWithArchive
          origin={isViewportMd ? 0 : 'center'}
          {...{ issues }}
        />
      </div>
    </div>
  )
}

function Sliders({ issues, specials, animations, layoutClassName = undefined }) {
  const [activeView, setActiveView] = React.useState('edition')
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const { __ } = useTranslate()

  const transRef = useSpringRef()
  const transition = useTransition(activeView, {
    ref: transRef,
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  React.useEffect(() => {
    if (activeView) transRef.start()
  }, [activeView, transRef])

  const options = [
    { value: 'edition', label: __`other-editions` },
    { value: 'specials', label: __`specials` },
  ]

  return (
    <div className={cx(styles.componentSliders, layoutClassName)}>
      <div className={styles.sliderContainer}>
        {transition((spring, item) => {
          const Component = item === 'edition'
            ? IssueSliderWithArchive
            : IssueSliderSpecials

          return (
            <animated.div key={item} style={{ ...(animations && animations.imageScale), ...spring }}>
              <Component
                origin={isViewportMd ? 0 : 'center'}
                issues={item === 'edition' ? issues : specials}
              />
            </animated.div>
          )
        })}
      </div>

      <Toggle
        value={activeView}
        onValueChange={setActiveView}
        layoutClassName={styles.toggleLayout}
        {...{ options }}
      />
    </div>
  )
}

function Heading({ title, animation = undefined, layoutClassName = undefined }) {
  return (
    <animated.h2 style={animation} className={cx(styles.componentHeading, layoutClassName)}>
      {title}
    </animated.h2>
  )
}

function Button({ animation = undefined }) {
  const { __ } = useTranslate()
  const { language } = useParams()

  return (
    <animated.div style={animation ? animation : undefined} className={styles.componentButton}>
      <ButtonLinkPrimary
        label={__`discover-other-regions`}
        href={routeMap.app.home({ language })}
        dataX='link-to-kaart'
        icon={mapIcon}
      />
    </animated.div>
  )
}
